<template>
  <v-card
    v-if="display"
    class="mx-auto"
    flat
  >
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col
            v-if="editStatus === 'N'"
            cols="12"
          >
            <v-text-field
              :label="$vuetify.lang.t('$vuetify.department.parent')"
              :value="parentDep.showName"
              disabled
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-row>
              <v-col
                cols="4"
              >
                <v-select
                  v-model="form.provinceId"
                  :label="$vuetify.lang.t('$vuetify.region.province')"
                  :items="provinceItems"
                  :rules="rules.province"
                  :disabled="!editStatus"
                  item-text="name"
                  item-value="id"
                  @change="handleProvinceChange"
                ></v-select>
              </v-col>
              <v-col
                cols="4"
              >
                <v-select
                  v-model="form.cityId"
                  :label="$vuetify.lang.t('$vuetify.region.city')"
                  :items="cityItems"
                  :disabled="!editStatus"
                  item-text="name"
                  item-value="id"
                  clearable
                  @change="handleCityChange"
                />
              </v-col>
              <v-col
                cols="4"
              >
                <v-select
                  v-model="form.countyId"
                  :label="$vuetify.lang.t('$vuetify.region.county')"
                  :items="countyItems"
                  :disabled="!editStatus"
                  item-text="name"
                  item-value="id"
                  clearable
                  @change="handleCountyChange"
                />
              </v-col>
            </v-row>

          </v-col>

          <v-col
            cols="12"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <v-select
                  v-model="form.townId"
                  :label="$vuetify.lang.t('$vuetify.region.town')"
                  :items="townItems"
                  :disabled="!editStatus"
                  menu-props="auto"
                  item-text="name"
                  item-value="id"
                  clearable
                  @change="handleTownChange"
                ></v-select>
              </v-col>

              <v-col
                cols="6"
              >
                <v-select
                  v-model="form.villageId"
                  :label="$vuetify.lang.t('$vuetify.region.village')"
                  :items="villageItems"
                  :disabled="!editStatus"
                  menu-props="auto"
                  item-text="name"
                  item-value="id"
                  clearable
                  @change="handleVillageChange"
                ></v-select>
              </v-col>

            </v-row>

          </v-col>

          <v-col
            cols="12"
          >
            <v-text-field
              v-model="form.showName"
              :label="$vuetify.lang.t('$vuetify.department.name')"
              :rules="rules.showName"
              :disabled="!editStatus"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
          >
            <v-select
              v-model="form.depLevel"
              :label="$vuetify.lang.t('$vuetify.department.level')"
              :items="depLevelItems"
              :rules="rules.depLevel"
              :disabled="!editStatus"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
          >
            <v-textarea
              v-model="form.description"
              :label="$vuetify.lang.t('$vuetify.department.description')"
              :disabled="!editStatus"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card-actions v-if="editStatus">
      <v-spacer />
      <v-btn
        color="primary"
        large
        tile
        @click="handelSubmit">
        {{ $vuetify.lang.t('$vuetify.common.submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  export default {
    props: {
      display: {
        type: Boolean,
        default: () => false
      },
      editStatus: {
        type: String,
        default: () => null
      },
      parentDep: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        form: {
          provinceId: null,
          cityId: null,
          countyId: null,
          showName: null,
          depLevel: null,
          description: null,
          regionId: null,
          townId: null,
          villageId: null
        },
        rules: {
          showName: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.department.name')])
          ],
          depLevel: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.department.level')])
          ],
          province: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.region.province')])
          ]
        },
        depLevelItems: [
          {
            id: 101,
            name: '省级中心医院'
          },
          {
            id: 201,
            name: '市级中心医院'
          },
          {
            id: 301,
            name: '县级中心医院'
          },
          {
            id: 401,
            name: '乡镇卫生院'
          }, {
            id: 501,
            name: '村级卫生室'
          }
        ],
        provinceItems: [],
        cityItems: [],
        countyItems: [],
        townItems: [],
        villageItems: []

      }
    },
    computed: {
      ...mapGetters({
        regions: 'region/regions'
      }),
    },
    created () {
      if (this.display) {
        this.initialize();
      }
    },
    methods: {
      ...mapActions ({
        regionQueryAll: 'region/queryAll'
      }),
      initialize () {
        if (this.editStatus != 'N') {
          this.form.regionId = this.parentDep.regionId
          if (this.parentDep.parentId != null) {
            this.form.provinceId = this.getRegionCode(2, this.form.regionId)
            this.form.cityId = this.getRegionCode(4, this.form.regionId)
            this.form.countyId = this.getRegionCode(6, this.form.regionId)
            this.form.townId = this.getRegionCode(9, this.form.regionId)
            this.form.villageId = this.getRegionCode(12, this.form.regionId)
            this.provinceItems = []
            this.cityItems = []
            this.countyItems = []
            this.townItems = []
            this.villageItems = []
            this.getRegion({parentId: ''}).then( () => {
              return this.getRegion({parentId: this.form.provinceId }, 'cityItems')
            }).then( () => {
              if (this.form.cityId) {
                return this.getRegion({parentId:  this.form.cityId }, 'countyItems')
              }
            }).then( () => {
              if (this.form.countyId) {
                return this.getRegion({parentId:  this.form.countyId }, 'townItems')
              }
            }).then( () => {
              if (this.form.townId) {
                return this.getRegion({parentId:  this.form.townId }, 'villageItems')
              }
            })
            this.form.parentId = this.parentDep.parentId
            this.form.showName = this.parentDep.showName
            this.form.depLevel = this.parentDep.depLevel
            this.form.description = this.parentDep.description
            this.form.depNo = this.parentDep.depNo
            this.form.id = this.parentDep.id
          }
        } else {
          this.getRegion({parentId: ''})
          this.resetFrom()
          this.form.parentId = this.parentDep.id
        }
      },
      getRegion (params = {}, target = 'provinceItems') {
        return new Promise((resolve, reject) => {
          this.regionQueryAll(params).then(() => {
            this[target] = this.regions
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      handelSubmit() {
        if (this.$refs.form.validate()) {
          const data = {}
          data.depLevel = this.form.depLevel
          data.depNo = this.form.depNo
          data.description = this.form.description
          data.parentId = this.form.parentId
          data.regionId = this.form.regionId
          data.showName = this.form.showName
          data.id = this.form.id
          this.$emit('submit', data);
        }
      },
      handleProvinceChange(id) {
        this.form.regionId = id
        this.getRegion({  parentId: id }, 'cityItems')
        this.cityItems = []
        this.countyItems = []
        this.townItems = []
        this.villageItems = []
      },
      handleCityChange(id) {
        if (id) {
          this.form.regionId = id
          this.getRegion({  parentId: id }, 'countyItems')
          this.countyItems = []
          this.townItems = []
          this.villageItems = []
        } else {
          this.form.regionId = this.form.provinceId
        }
      },
      handleCountyChange(id) {
        if (id) {
          this.form.regionId = id
          this.getRegion({  parentId: id }, 'townItems')
          this.townItems = []
          this.villageItems = []
        } else {
          this.form.regionId = this.form.cityId
        }
      },
      handleTownChange(id) {
        if (id) {
          this.form.regionId = id
          this.getRegion({  parentId: id }, 'villageItems')
          this.form.regionId = id
          this.villageItems = []
        } else {
          this.form.regionId = this.form.countyId
        }

      },
      handleVillageChange(id) {
        if (id) {
          this.form.regionId = id
        } else {
          this.form.regionId = this.form.townId
        }

      },
      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
      },
      getRegionCode(start, target) {
        let prefix = target.substr(0, start)
        if (prefix.endsWith('00')) {
          return null
        } else {
          return target.substr(0, start) + '0'.repeat(target.length - start)
        }
      }
    }
  }
</script>

<style lang="scss" scoped></style>
